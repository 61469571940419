"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeWebsocketManager = void 0;

var _isomorphicFetch = _interopRequireDefault(require("isomorphic-fetch"));

var _CentrifugeManager = _interopRequireDefault(require("./CentrifugeManager"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/* eslint-disable no-underscore-dangle */
var makeWebsocketManager = function makeWebsocketManager(url, tokenUrl) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (window.__wsInternal == null) {
    window.__wsInternal = {};
  }

  var name = options.name || 'default';
  var wsInternal = window.__wsInternal[name];

  if (wsInternal) {
    return wsInternal;
  }

  wsInternal = new _CentrifugeManager["default"]({
    channelName: options.channelName
  });
  (0, _isomorphicFetch["default"])(tokenUrl, {
    method: options.method || 'GET',
    headers: options.headers || {},
    credentials: options.credentials || 'same-origin'
  }).then(function (response) {
    return response.json();
  }).then(function (_ref) {
    var token = _ref.token,
        identifier = _ref.identifier;
    wsInternal.configure({
      url: url,
      token: token,
      identifier: identifier
    }, {
      subscribeHeaders: options.subscribeHeaders || {},
      subscribeEndpoint: options.subscribeEndpoint
    });
  });
  window.__wsInternal[name] = wsInternal;
  return wsInternal;
};

exports.makeWebsocketManager = makeWebsocketManager;